.gamesrepoBox {
    transition: all .3s ease-in-out;
    border: 5px #1d4899 solid;
    border-radius: 20px;
    padding: 12px;
    text-decoration: none;
    margin: 20px auto;
    max-width: 50%;
    margin-left: 75px;
    margin-right: 75px;
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    width: 300px; /* Set a width if needed */
    color: white;
    display: inline-block; /* Make the buttons appear below each other */
    border-bottom: none; /* Prevent double borders */
}

.gamesrepoBox:hover {
    transform: scale(1.1);
    border: 5px #0456ee solid;
    border-bottom: none; /* Prevent double borders */
    color: white;

}

.gamesrepoBoxTimestamp {
    color: grey;
    font-size: small;
} 


.gamesbuttonList {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.gamesrepoimg {
    max-width:100%;
    border-radius: 10px;
    box-shadow: 0 0 5px #1e4fec;

}
