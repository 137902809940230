.fadein {
    animation: fadeIn 3s ease-out;
  }

.link {
  text-decoration: none !important;
  color: #ffcb04 !important;
  cursor: help
}

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
    0% {transform: translateY(-35%);}
    25% {transform: translateY(0%);}
  }


  .circle-white {
    width: 8em;
    height: 8em;
    border: 2px dashed #f6f6f6;
    border-radius: 50%;
  }

  .preloader .text {
    color: #f6f6f6;
    width: 100%;
    text-align: center;
    left: 100%;
    right: 100%;
    margin: 0 auto;
    top: calc(50% - 5em);
    font-size: 2em;
    font-weight: bold;
  }

  .preloader .circle {
    animation: rotate-circle 4s linear infinite;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50px;
    bottom: 0;
  }

  .preloader .circle:after {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f6f6f6;
    position: absolute;
    top: calc(4em - 14px);
    left: calc(4em - 14px);
  }

  @keyframes rotate-circle {
    0% {
      transform: rotateZ(0deg);
    }
    100% { transform: rotateZ(720deg); }
  }

  .testSpin {
    animation: rotate-circle 4s linear infinite;
    text-align: center;
  }

  .arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 3px;
    cursor: pointer;
  }
  
  .right {
    cursor: pointer;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

 .centertext {
    width: 75%;
    text-align: center;
    margin: 0 auto;
}


.topText {
  justify-content: center;
  text-align: center;
  color: white;
  width: 50%;
  margin: 0 auto;
  padding: 0px;
  border-radius: 20px;
}

@media only screen and (min-width:576px) {
  .topText {
      width: 80%;
      font-size:x-large;
  }
}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
  .topText {
      width: 95%;
      font-size:large;

  }
}