.title-text {
    padding: 50px 50px;
    position: relative;
    text-align: center;
    color: white;
    font-weight: bold;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: black;
    font-size: 100px;

}

.title-desc {
    position: relative;
    text-align: center;
    justify-content: center;
    color: white;
    font-size: 50px;
    font-weight:900;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: black;
}

.title-box {
    border: 2px solid #000000c9;
}


/*PC brower*/

@media only screen and (min-width:576px) {
    .title-text {
        font-size: 150px;
    }

    .title-desc {
        font-size: 50px;
    }

}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
    .title-text {
        font-size: 75px;
    }

    .title-desc {
        font-size: 35px;
    }



}