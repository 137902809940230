.linkedin-box {
    padding: 20px;
    justify-content: center;
    display: flex;

}

.aboutBox {
    justify-content: center;
    text-align: center;
    color: white;
    width: 50%;
    margin: 0 auto;
    padding: 0px;
    border-radius: 20px;
}


@media only screen and (min-width:576px) {
    .aboutBox {
        width: 60%;
    }
}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
    .aboutBox {
        width: 95%;
    }
}