.twitterBorder {
    justify-content: center;
    align-content: center;
    text-align: center;
    padding: 0 5%;
    margin-bottom: 10%;
}

/*PC brower*/

@media only screen and (min-width:576px) {
    .twitterBorder {
        padding: 50px 25%;
    }
}


/*Mobile brower*/

@media only screen and (max-width: 575px) {
    .twitterBorder {
        padding: 25px 5%;
    }
}